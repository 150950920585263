import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Draggable from 'react-draggable'; // Import Draggable
import { Chip, IconButton } from '@mui/material'; // Import Material UI components
import ZoomInIcon from '@mui/icons-material/ZoomIn'; // Import Zoom In Icon
import ZoomOutIcon from '@mui/icons-material/ZoomOut'; // Import Zoom Out Icon


const App = ({
    showSpeed,
    showTemp,
    showRPM,
    speedData,
    tempData,
    rpmData,
    setTrackIndex,
    chartWidth,
    race,
    day,
    driverSessions,
    collapsed,
    driver,
    showTable,
    isTry,
    track, hoverLatitude, hoverLongitude, trackIndex 
}) => {
    const chartHeight = 250;
    const maxDataLength = Math.max(
        ...speedData.map((d) => d.drivingData?.length),
        ...rpmData.map((d) => d.drivingData?.length),
        ...tempData.map((d) => d.drivingData?.length)
    );

    const xLabels = Array.from({ length: maxDataLength }, (_, i) => (i * (isTry ? 8 : 10)).toFixed(3));

    const [syncIndex, setSyncIndex] = useState(null);
    const [tableWidth, setTableWidth] = useState(window.innerWidth * 0.8); // State to control table width
    const [tableHeight, setTableHeight] = useState(300); // State to control table height

    const showXAxisRPM = showRPM && !showSpeed && !showTemp;
    const showXAxisSpeed = showSpeed && !showTemp;
    const showXAxisTemp = showTemp || (!showRPM && !showSpeed);
    let message;
    useEffect(() => {
        // Handle resize event to adjust tableWidth dynamically
        const handleResize = () => {
            setTableWidth(window.innerWidth * 0.8); // Reset table width to 70% of viewport width
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!race) {
        message = 'Please select a race';
    } else if (!day) {
        message = 'Please select a day';
    } else if (driver?.length === 0) {
        message = 'Please select a driver';
    }else {
        message = 'Select a lap by clicking on the driver chip';
    }

    const handleZoomIn = (event) => {
        event.stopPropagation(); // Prevent drag interference
        if (tableWidth < window.innerWidth * 0.82) {
            setTableWidth(tableWidth + 50);
            setTableHeight(tableHeight + 30);
        }
    };

    const handleZoomOut = (event) => {
        event.stopPropagation(); // Prevent drag interference
        if (tableWidth > 300) {
            setTableWidth(tableWidth - 50);
            setTableHeight(tableHeight - 30);
        }
    };

    const tableRef = useRef(null); 
    const zoomInRef = useRef(null);
    const zoomOutRef = useRef(null);

    useEffect(() => {
        const zoomInButton = zoomInRef.current;
        const zoomOutButton = zoomOutRef.current;
        console.log('ZoomInRef:', zoomInButton);
        console.log('ZoomOutRef:', zoomOutButton);

        if (!zoomInButton) {
            console.error('zoomInRef is not set properly.');
        }

        const handleTouchZoomIn = (event) => {
            event.preventDefault();
            handleZoomIn(event);
        };

        const handleTouchZoomOut = (event) => {
            event.preventDefault();
            handleZoomOut(event);
        };

        if (zoomInButton && zoomOutButton) {
            zoomInButton.addEventListener('touchstart', handleTouchZoomIn);
            zoomOutButton.addEventListener('touchstart', handleTouchZoomOut);
        }

        // Clean up listeners on component unmount
        return () => {
            if (zoomInButton && zoomOutButton) {
                zoomInButton.removeEventListener('touchstart', handleTouchZoomIn);
                zoomOutButton.removeEventListener('touchstart', handleTouchZoomOut);
            }
        };
    }, []);

    const handleTouchScroll = (event) => {
        const { scrollLeft, scrollTop } = tableRef.current;

        const startX = event.touches[0].clientX;
        const startY = event.touches[0].clientY;

        let initialScrollLeft = scrollLeft;
        let initialScrollTop = scrollTop;

        const onTouchMove = (moveEvent) => {
            const moveX = moveEvent.touches[0].clientX;
            const moveY = moveEvent.touches[0].clientY;

            const diffX = startX - moveX;
            const diffY = startY - moveY;

            // Scroll horizontally if movement is greater on the X-axis
            if (Math.abs(diffX) > Math.abs(diffY)) {
                tableRef.current.scrollLeft = initialScrollLeft + diffX;
            } else {
                // Scroll vertically if movement is greater on the Y-axis
                tableRef.current.scrollTop = initialScrollTop + diffY;
            }
        };

        const onTouchEnd = () => {
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', onTouchEnd);
        };

        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);
    };



    return (
        <div>
            {rpmData.length > 0 ? (
                <> {showTable && (
                   
                    <Draggable bounds="parent">
                            <div
                               
                                className="draggable-table-container"
                                style={{
                                    width: `${tableWidth}px`,
                                    overflow: 'auto',
                                    position: 'relative',
                                    zIndex:500,
                                  
                                }}
                                onTouchStart={handleTouchScroll} // Attach touch scroll handler
                            >
                                <div className="table-controls" style={{ position: 'absolute', top: 5, right: 5 }}>
                                    <IconButton
                                        ref={zoomInRef}
                                        onClick={handleZoomIn}
                                        onTouchStart={handleZoomIn}
                                        style={{
                                            display: tableWidth < window.innerWidth * 0.9 ? 'inline-flex' : 'none',
                                            zIndex:500,
                                        }}
                                    >
                                        <ZoomInIcon   ref={tableRef}/>
                                    </IconButton>
                                    <IconButton
                                        ref={zoomOutRef}
                                        onClick={handleZoomOut}
                                        onTouchStart={handleZoomOut}
                                        style={{
                                            display: tableWidth > 200 ? 'inline-flex' : 'none',
                                            zIndex:500,
                                        }}
                                    >
                                        <ZoomOutIcon />
                                    </IconButton>
                                </div>
                                <LegendTable
                                    syncIndex={syncIndex}
                                    rpmData={rpmData}
                                    speedData={speedData}
                                    tempData={tempData}
                                    showRPM={showRPM}
                                    showTemp={showTemp}
                                    showSpeed={showSpeed}
                                />
                            </div>
                        </Draggable>
                )}
                    <div className="app-container">
                        {showRPM && rpmData.length > 0 && (
                            <SynchronizedChart
                                id="chart1"
                                data={rpmData.map((entry) => entry.drivingData?.map((d) => Object.values(d)[0]))}
                                emails={rpmData.map((entry) => entry.email)}
                                laps={rpmData.map((entry) => entry.lap)}
                                yLabel="RPM"
                                baseColor="blue"
                                syncIndex={syncIndex}
                                setSyncIndex={setSyncIndex}
                                showXAxis={showXAxisRPM}
                                moreMargin={true}
                                hideGrid={false}
                                hideVerticalGrid={false}
                                xLabels={xLabels}
                                setTrackIndex={setTrackIndex}
                                height={chartHeight - 20}
                                chart2={false}
                                chartWidth={chartWidth}
                                collapsed={collapsed}
                            />
                        )}
                        {showSpeed && speedData.length > 0 && (
                            <SynchronizedChart
                                id="chart2"
                                data={speedData.map((entry) => entry.drivingData?.map((d) => Object.values(d)[0]))}
                                emails={speedData.map((entry) => entry.email)}
                                laps={rpmData.map((entry) => entry.lap)}
                                yLabel="Speed"
                                baseColor="purple"
                                syncIndex={syncIndex}
                                setSyncIndex={setSyncIndex}
                                showXAxis={showXAxisSpeed}
                                hideGrid={false}
                                hideVerticalGrid={false}
                                xLabels={xLabels}
                                setTrackIndex={setTrackIndex}
                                height={chartHeight}
                                chart2={true}
                                chartWidth={chartWidth}
                                collapsed={collapsed}
                            />
                        )}
                        {showTemp && tempData.length > 0 && (
                            <SynchronizedChart
                                id="chart3"
                                data={tempData.map((entry) => entry.drivingData?.map((d) => Object.values(d)[0]))}
                                emails={tempData.map((entry) => entry.email)}
                                laps={rpmData.map((entry) => entry.lap)}
                                yLabel="Temp"
                                baseColor="red"
                                syncIndex={syncIndex}
                                setSyncIndex={setSyncIndex}
                                showXAxis={showXAxisTemp}
                                hideGrid={false}
                                hideVerticalGrid={false}
                                xLabels={xLabels}
                                setTrackIndex={setTrackIndex}
                                height={chartHeight}
                                chart2={false}
                                chartWidth={chartWidth}
                                collapsed={collapsed}
                            />
                        )}
                    </div>

                </>
            ) : (
                <div className="grid-demo-no-data-container">
                    {message && (
                        <>
                            <p className="grid-demo-no-data-text">{message}</p>
                            <i className="grid-demo-info-icon fas fa-info-circle"></i>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const groupDataByEmail = (data) => {
    const groupedData = {};
    data.forEach((entry) => {
        const { email, lap, surname } = entry;
        if (!groupedData[email]) {
            groupedData[email] = [];
        }
        groupedData[email].push( { lap,surname, drivingData: entry.drivingData });
    });
    return groupedData;
};


const LegendTable = ({ syncIndex, rpmData, speedData, tempData, showRPM, showSpeed, showTemp }) => {
    const groupedRpmData = groupDataByEmail(rpmData);
    console.log(groupedRpmData)
    const groupedSpeedData = groupDataByEmail(speedData);
    const groupedTempData = groupDataByEmail(tempData);

    return (
        <div className="legend-table">
            {Object.keys(groupedRpmData).map((email) => (
                <div key={email} style={{zIndex:500, backgroundColor:'white'}}>
                    <h3>{groupedRpmData[email][0].surname}</h3>
                    <table>
                        <thead>
                            <tr style={{zIndex:500}}>
                                <th>Lap (Session)</th>
                                {showRPM && <th>RPM</th>}
                                {showSpeed && <th>Speed (km/h)</th>}
                                {showTemp && <th>Temperature (°C)</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {groupedRpmData[email].map((entry, lapIndex) => {
                                const rpmValue =
                                    groupedRpmData[email][lapIndex]?.drivingData[syncIndex]?.Rpm || 'N/A';
                                const speedValue =
                                    groupedSpeedData[email]?.[lapIndex]?.drivingData[syncIndex]?.SpeedGps || 'N/A';
                                const tempValue =
                                    groupedTempData[email]?.[lapIndex]?.drivingData[syncIndex]?.TK || 'N/A';

                                return (
                                    <tr key={lapIndex}>
                                        <td>{`Lap ${entry.lap}`}</td>
                                        {showRPM && <td>{rpmValue}</td>}
                                        {showSpeed && <td>{speedValue}</td>}
                                        {showTemp && <td>{tempValue}</td>}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default App;



const SynchronizedChart = ({
    id,
    data,
    emails,
    yLabel,
    baseColor,
    syncIndex,
    laps,
    setSyncIndex,
    showXAxis,
    moreMargin,
    hideGrid,
    hideVerticalGrid,
    xLabels,
    setTrackIndex,
    height,
    chart2,
    chartWidth,
    collapsed
}) => {
    const svgRef = useRef();
    const yRange = 201.8;
    const containerRef = useRef();
    const [dimensions, setDimensions] = useState({ width: chartWidth, height });
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const boundingRect = containerRef.current.getBoundingClientRect();
                setDimensions({
                    width: Math.min(boundingRect.width, 1500),
                    height
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [height]);

    useEffect(() => {
        const margin = { top: !showXAxis ? 25 : 0, right: 10, bottom: showXAxis ? 20 : 5, left: 35 };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current)
            .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .select('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        svg.selectAll('*').remove();

        const yValues = data.flat();
        const yMin = d3.min(yValues);
        const yMax = d3.max(yValues);
        const yBuffer = (yMax - yMin) * 0.1;

        const x = d3.scaleLinear()
            .domain([0, xLabels.length - 1])
            .range([0, width]);

        const y = d3.scaleLinear()
            .domain([yMin - yBuffer, yMax + yBuffer])
            .range([yRange, 0]);

        const colorScale = d3.scaleSequential()
            .domain([0, data.length - 1])
            .interpolator(d3.interpolateRgb(baseColor, baseColor));

        if (showXAxis) {
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${height})`)
                .call(d3.axisBottom(x).ticks(5).tickSize(-height).tickFormat((d, i) => xLabels[i]));
        }

        svg.append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(y).ticks(5).tickSize(hideVerticalGrid ? 0 : -width).tickSizeOuter(0));

        data.forEach((lineData, lineIndex) => {
            const line = d3.line()
                .x((d, i) => x(i))
                .y(d => y(d));

            svg.append('path')
                .datum(lineData)
                .attr('class', `line line-${lineIndex}`)
                .attr('d', line)
                .attr('stroke', colorScale(lineIndex))
                .attr('stroke-width', 2)
                .attr('fill', 'none');
        });

        svg.append('line')
            .attr('x1', width)
            .attr('y1', 0)
            .attr('x2', width)
            .attr('y2', !showXAxis ? yRange : height)
            .attr('stroke', '#000')
            .attr('stroke-width', 2);

        svg.append('line')
            .attr('class', 'cursor-line')
            .attr('y1', 0)
            .attr('y2', height)
            .attr('stroke', '#000')
            .attr('stroke-width', 2)
            .style('display', 'none');

        svg.append('circle')
            .attr('class', 'cursor-point')
            .attr('r', 4)
            .attr('fill', baseColor)
            .style('display', 'none');

        svg.append('text')
            .attr('class', 'tooltip-text')
            .attr('fill', '#000')
            .style('font-size', '12px')
            .style('display', 'none');

        svg.append('rect')
            .attr('width', width)
            .attr('height', yRange)
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .on('mousemove', function (event) {
                handleMouseMove(event);
            })
            .on('touchstart', function (event) {
                handleTouchStart(event);
            })
            .on('touchmove', function (event) {
                handleTouchMove(event);
            });

        function handleMouseMove(event) {
            const [xPos] = d3.pointer(event);

            const index = Math.round(x.invert(xPos));
            setSyncIndex(index);
            setTrackIndex(index);
        }

        let startX = 0;
        let startY = 0;

        function handleTouchStart(event) {
            startX = event.touches[0].clientX;
            startY = event.touches[0].clientY;
        }

        function handleTouchMove(event) {
            const correction = event.target.getBoundingClientRect().left; // The left edge distance of the rect
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            const dx = Math.abs(touchX - startX);
            const dy = Math.abs(touchY - startY);

            if (dx > dy) {
                event.preventDefault();

                const width = window.innerWidth;

                // Ora puoi usare `boxWidth` come faresti con `window.innerWidth`
                let correctionFactor;
                if (width <= 400) {
                    correctionFactor = 0.14;
                } else if (width <= 800) {
                    correctionFactor = 0.15;
                } else {
                    correctionFactor = 0.18;
                }

                const adjustedX = touchX - ((!collapsed ? window.innerWidth + 500 : window.innerWidth) * correctionFactor);
                // Adjust touchX by the correction value
                const boundedX = Math.max(0, Math.min(adjustedX, width)); // Ensure boundedX is within the width of the rect
                const index = Math.round(x.invert(boundedX));
                setSyncIndex(index);
                setTrackIndex(index);
            } else {
                window.scrollBy(0, dy);
            }
        }



        return () => {
            svg.selectAll('*').remove();
        };
    }, [data, baseColor, showXAxis, dimensions, hideGrid, hideVerticalGrid, xLabels]);

    useEffect(() => {
        const svg = d3.select(svgRef.current).select('g');
        const width = dimensions.width - 10 - 35;
        const height = dimensions.height - 10 - 30;

        const x = d3.scaleLinear()
            .domain([0, xLabels.length - 1])
            .range([0, width]);

        const yValues = data.flat();
        const yMin = d3.min(yValues);
        const yMax = d3.max(yValues);
        const yBuffer = (yMax - yMin) * 0.1;

        const y = d3.scaleLinear()
            .domain([yMin - yBuffer, yMax + yBuffer])
            .range([height, 0]);

        const colorScale = d3.scaleSequential()
            .domain([0, data.length - 1])
            .interpolator(d3.interpolateRgb(baseColor, baseColor));

        const cursorLine = svg.select('.cursor-line');
        const point = svg.select('.cursor-point');
        const tooltipText = svg.select('.tooltip-text');

        if (syncIndex !== null && syncIndex < xLabels.length) {
            const xPos = x(syncIndex);

            cursorLine
                .attr('x1', xPos)
                .attr('x2', xPos)
                .style('display', null);

            point
                .attr('cx', xPos)
                .attr('cy', d => y(data[0][syncIndex] !== undefined ? data[0][syncIndex] : 0))
                .style('display', null);
            {/*const tooltipTextContent = data.map((lineData, lineIndex) =>
                `<tspan x="${xPos + 10}" dy="1.2em"><tspan fill="${colorScale(lineIndex)}">●</tspan> ${emails[lineIndex]}-${laps[lineIndex]}: ${lineData[syncIndex]}</tspan>`
            ).join('');

            tooltipText
                .html(`<tspan x="${xPos + 10}" dy="1.2em">Time: ${xLabels[syncIndex] / 50}</tspan>${tooltipTextContent}`)
                .attr('x', xPos + 10)
                .attr('y', y(data[0][syncIndex]) - 25)
                .style('display', null);*/}
        } else {
            cursorLine.style('display', 'none');
            point.style('display', 'none');
            tooltipText.style('display', 'none');
        }
    }, [syncIndex, data, dimensions, xLabels, emails, laps, baseColor]);

    return (
        <div ref={containerRef} style={{ height: `${height}px`, width: '100%' }}>
            <svg ref={svgRef}><g /></svg>
        </div>
    );
};
