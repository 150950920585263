import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  Fade,
  Grid,
  Button,
  styled,
  useMediaQuery,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PayPalButton from 'components/PayPalButton';
import Plan from 'components/Plans/Plan';
import PlanExternal from 'components/Plans/PlanExternal';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5d5fef',
  color: '#fff',
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#4a4cda',
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#fff',
  padding: '7px 24px',
  borderColor: 'transparent',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#fff',
    color: '#000',
  },
}));

const pricingOptions1 = [
  {
    chip: 'Basic',
    chip: {
      color: 'primary',
      label: 'Basic',
      variant: 'outlined',
    },
    title: 'Growth',
    currency: '€',
    description:
      'For drivers racing individually looking to use AiMotor to improve their performance            ',
    price: '€25/month ',
    yearPrice: '€280/year',
    priceNumber: 25,
    yearPriceNumber: 280,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
    ],
    buttonLabel: 'Start Now',
  },
  {
    chip: {
      color: 'secondary',
      label: 'most popular',
      variant: 'contained',
    },
    title: 'Pro',
    currency: '€',
    price: '€55/month',
    description:
      'For drivers seeking to use AiMotor to compare and improve their performance within a single, exclusive group of drivers and with our best AI model',
    yearPrice: '€640/year',
    priceNumber: 55,
    yearPriceNumber: 640,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create One Team (up to 5 members)',
    ],
    buttonLabel: 'Start Now',
  },
  {
    chip: {
      color: 'success',
      label: 'best',
      variant: 'contained',
    },
    title: 'Team',
    price: '€70/month',
    description:
      'For drivers seeking to use AiMotor to compare and improve their performance with multiple and large groups of drivers and with our best AI model',
    yearPrice: '€820/year',
    currency: '€',
    priceNumber: 70,
    yearPriceNumber: 820,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create Unlimited teams',
      'Invite Unlimited team members',
    ],
    buttonLabel: 'Start Now',
  },
];

const initialOptions = {
  'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: 'EUR',
  intent: 'subscription',
  vault: true,
};

function Plans({
  isExternal = false,
  onPlanClick = () => {},
  onSuccess = () => {},
  onUndo = () => {},
  
  showAll,
}) {
  const [loading, setLoading] = useState(false);
  const [plainId, setPlanId] = useState();
  const [period, setPeriod] = useState('MONTH');
  const [showPaypal, setShowPaypal] = useState(false);
  const [newTmpPlan, setNewTmpPlan] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedYearPrice, setSelectedYearPrice] = useState(0);
  const [pricingOptions, setPricingOptions] = useState([]);
  const scrollToRef = useRef(null); // Ref for scrolling
  const [scrollToPaypal, setScrollToPaypal] = useState(false);
  const paypalRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  debugger
  const myPlan =
    !showAll || myUserStore?.subscriptionDetails === undefined
      ? myUserStore?.subscriptionDetails?.custom_id
      : undefined;
  const isTrial = !myPlan;
console.log("trial", isTrial, myPlan)
console.log("myUserStore?.subscriptionDetails", myUserStore?.subscriptionDetails, myUserStore?.subscriptionDetails?.custom_id)
  useEffect(() => {
    let filteredOptions;
    if (myPlan) {
      filteredOptions = pricingOptions1.filter((option) => {
        if (myPlan === 'Growth') return true;
        if (myPlan === 'Pro')
          return option.title === 'Pro' || option.title === 'Team';
        if (myPlan === 'Team') return option.title === 'Team';
        return false;
      });
    } else {
      filteredOptions = pricingOptions1;
    }
    setPricingOptions(filteredOptions);
  }, [myPlan]);

  const sliderOptions = {
    slidesPerView: 3,
    spaceBetween: 16,
    breakpoints: {
      320: {
        centeredSlides: true,
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      900: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  };

  const selectedPlanId = myUserStore.subscriptionDetails?.custom_id;
  const subscriptionId = myUserStore.subscriptionId;

  const onUndoPayment = () => {
    setShowPaypal(false);
    setNewTmpPlan(undefined);
    onUndo();
  };

  const onPaymentComplete = (subscriptionDetails) => {
    myUserStore.setUserSubscriptionDetails(subscriptionDetails);
    setNewTmpPlan(undefined);
    setShowPaypal(false);
    onSuccess();
  };

  useEffect(() => {
    if (showPaypal && scrollToPaypal && paypalRef.current) {
      paypalRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToPaypal(false); // Reset the scroll state
    }
  }, [showPaypal, scrollToPaypal]);

  const handlePlanClick = (plan, isActive) => {
    if (!isActive) {
      setShowPaypal(true);
      setSelectedPrice(plan.priceNumber);
      setSelectedYearPrice(plan.yearPriceNumber);
      setNewTmpPlan(plan);
      setScrollToPaypal(true); // Set scroll state to true to trigger scrolling after rendering
      onPlanClick(plan);
    }
  };

  const handleSwitchChange = (event, value) => {
    if (value !== null) {
      setPeriod(value);
      onUndoPayment();
    }
  };

  const PlanByType = useCallback(
    (props) => {
      return isExternal ? <PlanExternal {...props} isTrial={isTrial}/> : <Plan {...props} />;
    },
    [isExternal]
  );

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
      }}
    >
      <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  flexDirection="column"
  p={2}
  borderRadius={2}
  width="100%"
>
  <Typography
    variant={isMobile ? 'h4' : 'h3'}
    style={{
      color: '#5d5fef',
      fontWeight: 'bold',
      marginBottom: '16px',
    }}
  >
    Build with AiMotor
  </Typography>

  <Typography
    variant={isMobile ? 'h6' : 'h5'}
    style={{
      color: '#fff',
      textAlign: 'center',
      marginTop: '8px',
    }}
  >
    Customized plans to give you exactly what you need
  </Typography>
  <Typography
    variant={isMobile ? 'h6' : 'h5'}
    style={{
      color: '#fff',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '8px',
    }}
  >
    Try one of the three plans
    <span
      style={{
        marginLeft: 6,
        background: 'linear-gradient(90deg, #6a5af9, #ae52d4)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 'bold',
        fontSize: '1.2em', // Slightly larger for emphasis
      }}
    >
      free for the first month
    </span>
  </Typography>

  <Typography
    variant="body1"
    style={{
      color: '#fff',
      textAlign: 'center',
      marginTop: '16px',
      fontSize: '1.3em',
      textDecoration: 'underline',
    }}
  >
    You can cancel your subscription at any time!
  </Typography>
</Box>

      {/* Monthly/Yearly Toggle */}
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <ToggleButtonGroup
          color="light"
          size="small"
          value={period}
          exclusive
          onChange={handleSwitchChange}
          aria-label="Billing Period"
          sx={{
            overflow: 'hidden',
            borderRadius: '30px',
            border: '1px solid #fff',
          }}
        >
          <StyledToggleButton value="MONTH">MONTHLY</StyledToggleButton>
          <StyledToggleButton value="YEAR">YEARLY</StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box as={Swiper}  {...sliderOptions} sx={{ width: '100%', padding: isMobile ? ' 16px' : '16px 80px', }}>
     
        {pricingOptions.map((option, index) => {
          const hasPlan = newTmpPlan && newTmpPlan?.title;
          const isSelected =
            newTmpPlan?.title && newTmpPlan.title === option.title;

          // Determine specific styles for 'Pro' card
          const isProCard = option.title === 'Pro';
          const cardBackgroundColor = isProCard ? '#fff' : '#000';
          const cardTextColor = isProCard ? '#000' : '#fff';

          return (
            <SwiperSlide key={index} style={{ height: 'auto' }}>

              <Box
                sx={{
                  backgroundColor: cardBackgroundColor,  // Specific background color for 'Pro' card
                  color: cardTextColor,  // Specific text color for 'Pro' card
                  borderRadius: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'space-between',
                  height:'100%',
                  minHeight: '500px',
                  opacity: hasPlan ? (isSelected ? 1 : 0.2) : 1,
                  pointerEvents: hasPlan && !isSelected ? 'none' : 'auto',
                }}
              >
                <PlanByType
                  plan={option}
                  isTrial={isTrial}
                  period={period}
                  isActive={selectedPlanId === option.title}
                  loading={loading}
                  onClick={handlePlanClick}
                />
              </Box>
            </SwiperSlide>
          );
        })}
      </Box>

      <Fade in={showPaypal} unmountOnExit>
        <Box px={3} ref={paypalRef}>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButton
              period={period}
              price={period === 'MONTH' ? selectedPrice : selectedYearPrice}
              planId={plainId}
              currentSubscriptionId={subscriptionId}
              selectedPlan={newTmpPlan}
              onPaymentComplete={onPaymentComplete}
              onUndoPayment={onUndoPayment}
              isTrial={isTrial}
            />
          </PayPalScriptProvider>
        </Box>
      </Fade>
    </Box>
  );
}

export default observer(Plans);
