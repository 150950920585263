import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import PropTypes from 'prop-types';
import axios from 'axios';
// Adjust the path as necessary

import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// MUI Components
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Menu,
  OutlinedInput,
  Popover,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PieChart } from '@mui/x-charts/PieChart';

import Select, { SelectChangeEvent } from '@mui/material/Select';

// Custom Components
import CorniceFront from './CorniceFront';
import CornicePressure from './CornicePressure';
import FilesRecap from 'components/FilesRecap/Recap.js';
import InsertData from "./InsertData.js";
import InsertDriver from './InsertDriver.js';
import ManagerForm from "../ManagerForm.js";
import TelemetryDownload from './TelemetryDownload.js';
import UploadTelemetry from './UploadTelemetry.js';
// Import desktop images
import STR1 from '../../img/starlane1.jpg';
import STR2 from '../../img/starlane2.jpg';
import STR3 from '../../img/starlane.jpg';
import STR4 from '../../img/starlane_new.png';
import UNI1 from '../../img/unipro1.jpg';
import UNI2 from '../../img/unipro2.jpg';
import UNI3 from '../../img/unipro3.jpg';
import MYCRON1 from '../../img/mycron1.png';
import MYCRON2 from '../../img/mycron2.jpg';
import MYCRON3 from '../../img/mycron3.jpg';
import MYCRON5 from '../../img/mychron_new.png';
import MYCRON4 from '../../img/mycron4.jpg';

// Import mobile images
import STR1_MOBILE from '../../img/starlane_mobile1.jpg';
import STR2_MOBILE from '../../img/starlane_mobile2.jpg';
import STR3_MOBILE from '../../img/starlane_mobile3.jpg';
import STR4_MOBILE from '../../img/starlane_mobile_new.jpg';
import UNI1_MOBILE from '../../img/unipro_mobile_new.jpg';
import UNI2_MOBILE from '../../img/unipro_mobile2.jpg';
import UNI3_MOBILE from '../../img/unipro_mobile3.jpg';
import MYCRON1_MOBILE from '../../img/mycron_mobile1.png';
import MYCRON2_MOBILE from '../../img/mycron_mobile2.jpg';
import MYCRON3_MOBILE from '../../img/mycron_mobile3.jpg';
import MYCRON4_MOBILE from '../../img/mychrom_new_mobile.png';
import MYCRON5_MOBILE from '../../img/mycron_mobile4.jpg';

// Store
import myUserStore from '../../stores/UserStore';

const StepperDot = styled(Box)(({ theme, active }) => ({
  width: active ? 10 : 8,
  height: active ? 10 : 8,
  borderRadius: "50%",
  margin: theme.spacing(0.5),
  backgroundColor: active ? theme.palette.primary.main : "rgba(0, 0, 0, 0.3)",
}));

// Constants for Menu
const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};

// Helper Function for Styles
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// Custom Tab Panel Component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Accessibility Props for Tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = () => {
  // State Variables
  const [refreshUploadList, setRefreshUploadList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);

  const [showFront, setShowFront] = useState(false);
  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true); // Use showEngine for engine graphs
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  // New State for Help Dialog and Image Display
  const [helpOpen, setHelpOpen] = useState(false);
  const [selectedHelpImage, setSelectedHelpImage] = useState(null); // null means no image selected
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Theme
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const devices = {
    starlane: {
      images: {
        mobile: [STR4_MOBILE, STR1_MOBILE, STR2_MOBILE, STR3_MOBILE], // Add a duplicate of the third image
        desktop: [STR4, STR1, STR2, STR3], // Add a duplicate of the third image
      },
      instructions: [
        "1. Click on 'Session Archive'.",
        "2. Double Click on a lap.",
        "3. Click on 'Tools'.",
        "4. Click on 'Export CSV' and choose 'full session' ",
      ],
      tutorialLink: "https://youtu.be/XRyxjavnSbs?si=XJpVzo8buw8CQlYj",
    },
    unipro: {
      images: {
        mobile: [UNI1_MOBILE, UNI2_MOBILE, UNI3_MOBILE], // Add a duplicate of the third image
        desktop: [UNI1, UNI2, UNI3], // Add a duplicate of the third image
      },
      instructions: [
        "1. Choose the session to export",
        "2. Click on 'Export Session' --> 'For dashware'",
        "3. Export all fields.",
        
      ],
      tutorialLink: "https://youtu.be/VwUZoQFJtK4?si=NUsF60-4VF94Ymuo",
    },
    mychron: {
      images: {
        mobile: [MYCRON1_MOBILE,MYCRON2_MOBILE, MYCRON4_MOBILE,MYCRON3_MOBILE , MYCRON5_MOBILE ], // Add a duplicate of the third image
        desktop: [MYCRON1, MYCRON3, MYCRON5, MYCRON4, MYCRON2], // Add a duplicate of the third image
      },
      instructions: [
        "1. Click on Preferences --> Data Download Preferences",
        "2. Select 'Advanced' and tick the CSV checkbox.",
        "3. Click on Analysis to view your sessions",
        "4. Right click on the session file you want to export.",
        "5. Select 'Export Item' --> Race Studio2.CSV",
      ],
      tutorialLink: "https://youtu.be/MGno9R8r0I0?si=WB8AjA2KO48bS-jN",
    },
  };


  const sliderOptions = {
    slidesPerView: 3,
    spaceBetween: 16,
    breakpoints: {
      320: {
        centeredSlides: true,
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      900: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  };

  const handleDeviceSelect = (deviceKey) => {
    const device = devices[deviceKey];
    
    if (!device) {
      console.error(`Device with key ${deviceKey} does not exist.`);
      return;
    }
    
    // Choose images based on mobile or desktop
    const images = isMobile ? device.images.mobile : device.images.desktop;
    
    if (!Array.isArray(images) || images.length === 0) {
      console.error(`Images for device ${deviceKey} are invalid or empty.`);
      return;
    }
    
    // Limit to 3 images and instructions for "unipro"
    const completeImages = deviceKey === "unipro" ? images.slice(0, 3) : [...images];
    const completeInstructions = deviceKey === "unipro" ? device.instructions.slice(0, 3) : [...device.instructions];
    
    while (completeImages.length < 4 && deviceKey !== "unipro") {
      completeImages.push(completeImages[completeImages.length - 1]);
    }
    
    while (completeInstructions.length < 4 && deviceKey !== "unipro") {
      completeInstructions.push(completeInstructions[completeInstructions.length - 1]);
    }
    
    setSelectedDevice({ 
      ...device, 
      images: completeImages, 
      instructions: completeInstructions 
    });
    
    setCurrentImageIndex(0); // Reset to the first image
    setHelpOpen(true); // Open the help dialog
  };

  
  


  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 === selectedDevice.images.length ? 0 : prevIndex + 1
    );
  };

  // Navigate to previous image
  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedDevice.images.length - 1 : prevIndex - 1
    );
  };

  // Handlers for Tabs
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0);
    setShowFront(newValue === 1);
    setShowPressure(newValue === 2);
  };
  const handleChangeTab1 = (newValue) => {
    setValue(0);
    setShowEngine(true);
    setShowFront(false);
  };

  // Handler for Date Change
  const handleDateChange = (date) => {
    setValue(1);
    setSelectedDate(date);
    handleMenuClose();
  };

  // Menu Handlers
  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log("selected team", selectedTeam.id);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Upload Completion Handler
  const handleUploadComplete = (status) => {
    setRefreshUploadList(status);
  };

  // Handlers for Help Dialog
  const handleHelpOpen = () => {
    setHelpOpen(true);
    setSelectedHelpImage(null); // Reset any selected image
  };
  const handleHelpClose = () => {
    setHelpOpen(false);
    setSelectedHelpImage(null); // Reset any selected image
  };

  // Handlers for Chip Clicks
  const handleChipClick = (mobileImageUrl, desktopImageUrl) => {
    setSelectedHelpImage(isMobile ? mobileImageUrl : desktopImageUrl);
  };
  // Handler to go back to Chip selection
  const handleBackToChips = () => {
    setSelectedHelpImage(null);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', }}>
      {/* Header */}
      <Grid
        item
        lg={showPersonalButton ? 6 : 4}
        md={showPersonalButton ? 6 : 4}
        sm={showPersonalButton ? 6 : 4}
        xs={12}
        sx={{ marginBlock: 2 }}
      >
        <Typography sx={{ fontSize: 40 }}>Telemetry Data</Typography>
      </Grid>

      {/* Tabs and Content */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab}>
            <Tab label="DOWNLOAD DATA" {...a11yProps(1)} />
            <Tab label="IMPORT DATA" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <div className='graphs'>
          {showEngine && (
            <UploadTelemetry
              race={race}
              day={day}
              driver={driver}
              fullName={emailName}
              showTable={!!race && !!day}
              onComplete={handleUploadComplete}
            />

          )}
          {showFront && (
            <TelemetryDownload
              race={race}
              day={day}
              driver={driver}
              fullName={emailName}
              showTable={!!race && !!day}
              handleChipClick={handleChangeTab1}
            />

          )}
        </div>

      </Box>

      {/* Floating QuestionMarkIcon */}
      <Box sx={{ position: 'fixed', bottom: 26, right: 26, zIndex: 1000 }}>
        <IconButton
          color="primary"
          aria-label="help"
          onClick={handleHelpOpen}
          sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            },
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Box>

      {/* Help Dialog */}
      <Box>
        {/* Dialog */}
        <Dialog
          open={helpOpen}
          onClose={handleHelpClose}
          aria-labelledby="help-dialog-title"
          fullWidth
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              padding: theme.spacing(3),
              borderRadius: theme.spacing(3),
              backgroundColor: "#f9f9f9",
            },
          }}
        >
          <DialogTitle
            id="help-dialog-title"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Telemetry Device Help
          </DialogTitle>
          <DialogContent>
            {selectedDevice === null ? (
              // Initial device selection screen
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                  mt: 2,
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  Select a device to learn more:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? 1 : 3,
                  }}
                >
                  <Chip
                    label="Starlane"
                    onClick={() => handleDeviceSelect("starlane")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                    }}
                  />
                  <Chip
                    label="Unipro"
                    onClick={() => handleDeviceSelect("unipro")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                    }}
                  />
                  <Chip
                    label="Mychron"
                    onClick={() => handleDeviceSelect("mychron")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                    }}
                  />
                </Box>
              </Box>
            ) : (
              // Device details with images and Go Back button
              <Box>
                

                {/* Image Viewer */}
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                    borderRadius: "12px",
                  }}
                >
                  <IconButton
                    onClick={handlePreviousImage}
                    sx={{
                      position: "absolute",
                      left: isMobile ? "-20px" : 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#1976d2",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <img
                    src={selectedDevice.images[currentImageIndex]}
                    alt={`Slide ${currentImageIndex + 1}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "12px",
                    }}
                  />
                  <IconButton
                    onClick={handleNextImage}
                    sx={{
                      position: "absolute",
                      right: isMobile ? "-20px" : 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#1976d2",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
                <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    mt: -3,
    flexDirection: "column",
    alignItems: "center",
  }}
>
  <Typography
    sx={{
      fontSize: isMobile ? "1rem" : "1.2rem", // Smaller font size for mobile
      fontWeight: "bold", // Bold text for visibility
      color: "#000000", // Black text color
      mt: 2, // Margin on top for separation
      textAlign: "center", // Centered text alignment
    }}
  >
    {selectedDevice.instructions[currentImageIndex]}
  </Typography>
</Box>



                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    
                  }}
                >
                  {selectedDevice?.images.map((_, index) => (
                    <StepperDot key={index} active={index === currentImageIndex} />
                  ))}
                </Box>

                {/* Instructions Section */}
                {/* Instructions Section */}




                {/* Stepper Indicators */}


                {/* Go Back and Tutorial Buttons */}

              </Box>
            )}
          </DialogContent>
          {/* Dialog Actions */}
          {selectedDevice && (
            <DialogActions
              sx={{
                justifyContent: "center", // Center the actions
                pb: 2,
                pt: 2// Add padding to the bottom for better spacing
              }}
            >
              <Chip
                label="Go Back"
                onClick={() => setSelectedDevice(null)}
                clickable
                sx={{
                  fontSize: "1rem",
                  padding: "12px 0px",
                  borderRadius: "24px", // Rounded edges
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.1)",
                  },
                }}
              />
              <Chip
                label="Watch Tutorial"
                onClick={() => window.open(selectedDevice.tutorialLink, "_blank")}
                clickable
                sx={{
                  fontSize: "1rem",
                  padding: "12px 20x",
                  borderRadius: "24px", // Rounded edges
                  color: "#fff",
                  backgroundColor: "#1976d2",
                  ml: 2, // Add margin between the two chips
                  "&:hover": {
                    backgroundColor: "#115293",
                  },
                }}
              />
            </DialogActions>
          )}


        </Dialog>
      </Box>

      {/* Uncomment if needed */}
      {/* <FilesRecap refreshList={refreshUploadList} onComplete={() => handleUploadComplete(false)} /> */}
    </Box>
  );
};

export default observer(PieChartWithSelect);
