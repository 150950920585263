import { Box, Chip, Typography, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import HttpProvider from 'services/HttpProvider';
import StepperContentLoader from './Loaders';
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import myUserStore from 'stores/UserStore';

const SelectRace = ({
  selectedTeam = {},
  activeRace = '',
  onChange = () => {},
  isTry,
  ...props
}) => {
  const competitions1 = [
    {
      id: 4,
      competition: "AiMotor Race",
      track: "Lonato",
    },
  ];
  const [competitions, setCompetitions] = useState(isTry ? competitions1 : []);
  const [isLoading, setIsLoading] = useState(false);
  const [race, setRace] = useState(activeRace);
  const [editMode, setEditMode] = useState(false); // State to toggle edit mode
  const history = useHistory();
  const fetchCompetitions = async () => {
    setIsLoading(true);
    return HttpProvider.get(`/get_competitions/?team_id=${selectedTeam.id}`)
      .then((res) => {
        setCompetitions(res.data);
      })
      .catch((err) => {
        console.error('Error fetching competitions:', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRaceClick = (id) => {
    setRace(id);
    onChange(id);
    setEditMode(false); // Exit edit mode when a race is selected
  };

  const handleInsertCompetition = () => {
    history.push('/racing-space/insert-competition');
  };

  const handleDeleteRace = (id) => {
    HttpProvider.delete(`/remove_competition/`, { params: { competition_id: id } })
      .then(() => {
        setCompetitions((prev) => prev.filter((competition) => competition.id !== id));
        toast.success('Race deleted successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error('Error deleting competition:', err);
        toast.error('Failed to delete the race. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (!isTry) {
      fetchCompetitions();
    }
  }, [selectedTeam]);

  return (
    <Box textAlign="center" {...props}>
      {isLoading ? (
        <StepperContentLoader />
      ) : competitions.length > 0 ? (
        <Box flexWrap="wrap">
          {competitions.map((c) => (
            <Chip
              label={`${c.competition} (${c.track})`}
              key={c.id}
              color={c.id === race ? 'primary' : 'default'}
              onClick={() => !editMode && handleRaceClick(c.id)} // Prevent selection in edit mode
              sx={{ mb: 1.5, mr: 2 }}
              icon={
                editMode && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent chip click in edit mode
                      handleDeleteRace(c.id);
                    }}
                  >
                    <DeleteIcon sx={{ color: 'red' }} />
                  </IconButton>
                )
              }
            />
          ))}
          {/* Edit Mode Toggle */}
          {!isTry && (<Box sx={{ marginTop: 2 }}>
            <Chip
              label={editMode ? "Stop Editing" : "Edit"}
              onClick={() => setEditMode((prev) => !prev)}
              sx={{
                fontSize: '0.9rem', // Slightly smaller
                padding: '8px 16px', // Slightly reduced padding
                backgroundColor: editMode ? '#e57373' : '#1976d2',
                color: '#fff',
                '&:hover': {
                  backgroundColor: editMode ? '#d32f2f' : '#115293',
                },
              }}
            />
          </Box>)}
        </Box>
      ) : (
        // If no competitions
        <Box marginTop={3}>
          <Typography sx={{ mb: 2, fontSize: '1rem', color: 'gray' }}>
            No competition inserted
          </Typography>
          <Chip
            label="Insert Competition"
            clickable
            onClick={handleInsertCompetition}
            sx={{
              fontSize: '1rem',
              padding: '12px 24px',
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#115293',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default observer(SelectRace);
