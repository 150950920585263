import 'components/components.css';

import { Bar, BarChart, Legend, XAxis, YAxis } from '@mui/x-charts/BarChart';
import React, { useEffect, useState,useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography, tooltip } from '@mui/material';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { DialogContent } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from "axios";
import { axisClasses } from '@mui/x-charts';
import myUserStore from '../../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { styled } from '@mui/material/styles';
import pressure from '../TryFiles/pressure_file.json';

const uniqueValues = (data, key) => {
  const unique = new Set(data.map(item => item[key]));
  return [...unique];
};



const MyComponent = ({ driver, race, day, fullName, showTable ,selectedSessions}) => {
  console.log(driver);
  const selectedName = driver.length > 0 ? fullName.filter(dataItem => driver.includes(dataItem.email)) : fullName;
  console.log("Selected Name", selectedName);
  console.log('Recived day:', day);
  const [selectedData, setSelectedData] = useState([]);
  const [axlepositionVisible, setAxlePositionVisible] = useState(showTable ? true : false);
  const [axletypeVisible, setAxleTypeVisible] = useState(showTable ? true : false);
  const [temperatureVisible, setTemperatureVisible] = useState(showTable ? true : false);
  const [chassisNumberVisible, setChassisNumberVisible] = useState(showTable ? true : false);
  const [isHidden, setIsHidden] = useState(true);
  const [racingData, setRacingData] = useState([]);
  const [sessionNumber, setSessionNumber] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [chartWidth, setChartWidth] = useState(window.innerWidth);
  console.log(chartWidth)
  let message;

  if (!race) {
    message = 'Please select a race';
  } else if (!day) {
    message = 'Please select a day';
  }
  const filterDataByDriverSessions = (data ) => {
    if (driver?.length>0) {
        return data.filter(item =>
            driver.includes(item.email) && 
            item.day == day
        );
    }else{
        return data.filter(item =>
            item.day == day
        );

    }
   
};


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850); // Adjust breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const extractModifiedLaptime = (racingData) => {
    const modifiedRacingData = racingData.map((item) => {
      const laptimeString = item.laptime;
      if (typeof laptimeString !== 'string') {
        console.error("laptimeString is not a string:", laptimeString);
        return null; // o qualsiasi altro valore significativo
      }
      const [hours, minutes, seconds] = laptimeString.split(":");
      const totalSeconds = parseInt(hours) * 60 + parseInt(minutes) + 0.01 * parseInt(seconds);
      const laptimeCalc = Math.round(totalSeconds * 1000) / 1000;


      // Restituisci un nuovo oggetto con laptimeCalc aggiunto
      return {
        ...item,
        laptimeCalc
      };
    }).filter(item => item !== null); // Rimuovi i valori nulli, se presenti

    return modifiedRacingData;
  };

  const scrollToTable = () => {
    tableRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const tableRef = useRef(null);

  useEffect(() => {
    const filterEngine = filterDataByDriverSessions(pressure);
    console.log('filterEngine', filterEngine)
    const datasetWithStats = calculateStats(filterEngine); // Calculate statistics
    let filteredData;
        if (selectedSessions) {
          filteredData = calculateStats(filterEngine).filter((item) =>
            selectedSessions.includes(item.session_number)
          );
        } else {
          filteredData = [];
        }
        setRacingData(filteredData); 

    setAxlePositionVisible(showTable ? true : false);
        setAxleTypeVisible(showTable ? true : false);
        setTemperatureVisible(showTable ? true : false);
        setChassisNumberVisible(showTable ? true : false);

  }, [driver, race, day, showTable, selectedSessions]);


  // Esempio di utilizzo
  const modifiedRacingData = extractModifiedLaptime(racingData);

  const sortedData = calculateStats(modifiedRacingData).sort((a, b) => a.session_number - b.session_number);
  const racingData1 = [...sortedData];


  console.log("Racing Data 1 with stats", racingData1);
  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = myUserStore.getSelectedTeam;

  



  const handleButtonClick = (buttonType) => {
    // Update the visibility of the clicked button
    if (buttonType === 'axleposition') {
      setAxlePositionVisible(!axlepositionVisible);
    } else if (buttonType === 'axletype') {
      setAxleTypeVisible(!axletypeVisible);
    } else if (buttonType === 'temperature') { // Handle temperature button click
      setTemperatureVisible(!temperatureVisible);
    }
    else if (buttonType === 'chassisNumber') { // Handle temperature button click
      setChassisNumberVisible(!chassisNumberVisible);
    }
    setButtonVisibility((prevVisibility) => ({
      ...prevVisibility,
      [buttonType]: !prevVisibility[buttonType],
    }));
  };
  const [buttonVisibility, setButtonVisibility] = useState({
    axleposition: false,
    axletype: false,
    temperature: false,
    chassisNumber: false,
  });



  useEffect(() => {
    // Create an object to store data for each unique email and session number combination
    const emailSessionMap = new Map();

    // const filteredData = dataset1.filter(item =>
    //   (!driver || (item.mail && driver.includes(item.mail)))
    // );


    // Iterate through the filtered data to populate the email session map
    racingData1.forEach(item => {
      const { email, session_number } = item;
      const key = `${email}-${session_number}`;



      if (!emailSessionMap.has(key)) {
        emailSessionMap.set(key, { email: item.email, session_number });
      }

      const sessionData = emailSessionMap.get(key);


      // Concatenate the values if the field already exists
      if (axletypeVisible && sessionData.axle_type) {
        sessionData.axle_type += `  |  ${item.axle_type}`;
      } else if (axletypeVisible) {
        sessionData.axle_type = `${item.axle_type}`;
      }

      if (axlepositionVisible && sessionData.axle_position) {
        sessionData.axle_position += ` |  ${item.axle_position}`;
      } else if (axlepositionVisible) {
        sessionData.axle_position = `${item.axle_position}`;
      }

      if (temperatureVisible && sessionData.temperature) {
        sessionData.temperature += ` | ${item.temperature}`;
      } else if (temperatureVisible) {
        sessionData.temperature = `${item.temperature}`;
      }

      if (chassisNumberVisible && sessionData.chassis_number) {
        sessionData.chassis_number += ` | ${item.chassis_number}`;
      } else if (chassisNumberVisible) {
        sessionData.chassis_number = `${item.chassis_number}`;
      }
      emailSessionMap.set(key, sessionData);
    });

    // Convert the map values to an array of selected data
    const newData = Array.from(emailSessionMap.values());
    setSelectedData(newData);
  }, [axlepositionVisible, axletypeVisible, temperatureVisible, racingData, chassisNumberVisible]);




  function calculateStats(dataset) {
    const stats = {};

    dataset.forEach(item => {
      const session_number = item.session_number;

      if (!stats[session_number]) {
        stats[session_number] = {
          camber: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          },
          laptimeCalc: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          },
          caster: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          },
          temperature: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          }
        };
      }

      const sessionStats = stats[session_number];

      // Update min_rpm stats
      if (item.camber < sessionStats.camber.min) {
        sessionStats.camber.min = item.camber;
      }
      if (item.camber > sessionStats.camber.max) {
        sessionStats.camber.max = item.camber;
      }
      sessionStats.camber.sum += item.camber;
      sessionStats.camber.count++;

      // Update laptime stats
      if (item.laptimeCalc < sessionStats.laptimeCalc.min) {
        sessionStats.laptimeCalc.min = item.laptimeCalc;
      }
      if (item.laptimeCalc > sessionStats.laptimeCalc.max) {
        sessionStats.laptimeCalc.max = item.laptimeCalc;
      }
      sessionStats.laptimeCalc.sum += item.laptimeCalc;
      sessionStats.laptimeCalc.count++;

      // Update min_exhaust stats
      if (item.caster < sessionStats.caster.min) {
        sessionStats.caster.min = item.caster;
      }
      if (item.caster > sessionStats.caster.max) {
        sessionStats.caster.max = item.caster;
      }
      sessionStats.caster.sum += item.caster;
      sessionStats.caster.count++;

      // Update max_exhaust stats


      // Upndate temperature stats
      if (item.temperature < sessionStats.temperature.min) {
        sessionStats.temperature.min = item.temperature;
      }
      if (item.temperature > sessionStats.temperature.max) {
        sessionStats.temperature.max = item.temperature;
      }
      sessionStats.temperature.sum += item.temperature;
      sessionStats.temperature.count++;
    });

    // Calculate averages and other metrics for each session
    for (const session_number in stats) {
      const sessionStats = stats[session_number];

      // Calculate averages for min_rpm and laptime
      sessionStats.camber.avg = Math.round((sessionStats.camber.sum / sessionStats.camber.count) * 1000) / 1000;
      sessionStats.laptimeCalc.avg = Math.round((sessionStats.laptimeCalc.sum / sessionStats.laptimeCalc.count) * 1000) / 1000;
      sessionStats.caster.avg = Math.round((sessionStats.caster.sum / sessionStats.caster.count) * 1000) / 1000;



      // Calculate additional metrics for min_rpm and laptime
      // Calculate additional metrics for min_rpm, laptime, min_exhaust, max_exhaust, and max_speed
      sessionStats.camber.avg_rpm1 = Math.round((sessionStats.camber.avg - sessionStats.camber.min) * 1000) / 1000;
      sessionStats.camber.max_rpm1 = Math.round((sessionStats.camber.max - sessionStats.camber.avg) * 1000) / 1000;
      sessionStats.laptimeCalc.avg_lap1 = Math.round((sessionStats.laptimeCalc.avg - sessionStats.laptimeCalc.min) * 1000) / 1000;
      sessionStats.laptimeCalc.max_lap1 = Math.round((sessionStats.laptimeCalc.max - sessionStats.laptimeCalc.avg) * 1000) / 1000;

      sessionStats.caster.avg_exhaust1 = Math.round((sessionStats.caster.avg - sessionStats.caster.min) * 1000) / 1000;
      sessionStats.caster.max_exhaust1 = Math.round((sessionStats.caster.max - sessionStats.caster.avg) * 1000) / 1000;

    }

    // Convert stats object to array
    const statsArray = Object.values(stats);


    const datasetWithStats = dataset.map(item => {
      return {
        ...item,
        stats: stats[item.session_number]
      };
    });

    return datasetWithStats;
  }


  const xLabels = [
    'Session 1',
    'Session 2',
    'Session 3',
    'Session 4',
    'Session 5',
    'Session 6',

  ];

  const seriesA = {
    data: [5, 2.5, 1],
    label: 'Series A',
  };
  const seriesB = {
    data: [0, 0, 0], // Set all values to 0 to avoid stacking
    label: 'Series B (Reference)',
  };
  const seriesC = {
    data: [0, 0, 0], // Set all values to 0 to avoid stacking
    label: 'Series C (Reference)',
  };

  const [stackVisibility, setStackVisibility] = useState({
    stack1: true,
    stack2: true,
    stack3: true,
    stack4: true,
    stack5: true,
  });

  const handleToggleStack = (stack) => {
    setStackVisibility({ ...stackVisibility, [stack]: !stackVisibility[stack] });
  };
  const [activeButton, setActiveButton] = useState(null);
  let add;


  const theme = createTheme({
    palette: {
      primary: { main: '#00897B', light: '#B2DFDB', dark: '#004D40' },
      secondary: { main: '#0057B2', },
      success: { main: '#7B1FA2', },
      info: { main: '#AD1457' },
      error: { main: '#E64A19' }

    },
  });
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const sessionNumbers = []; // Array per tenere traccia dei session number già visti
  let numberOfCells = 0;

  const handleZoomIn = () => {
    setChartWidth((prevWidth) => prevWidth + 100);
  };

  const handleZoomOut = () => {
    setChartWidth((prevWidth) => Math.max(prevWidth - 100, 100));
  };
  return (


    <div className='frame'>


      <><div className='textEngine'>

        <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE VISUALIZATION </Typography>
        <div className='engineButtons'>
          <ThemeProvider theme={theme}>
            <Chip onClick={() => handleToggleStack('stack1')} label="CAMBER" color='primary' size="medium" variant={stackVisibility['stack1'] ? "filled" : "outlined"} checked={stackVisibility.stack1} />
            <Chip onClick={() => handleToggleStack('stack2')} label="LAP TIME" color="secondary" size="medium" variant={stackVisibility['stack2'] ? "filled" : "outlined"} checked={stackVisibility.stack2} />
            <Chip onClick={() => handleToggleStack('stack3')} label="CASTER" color='success' size="medium" variant={stackVisibility['stack3'] ? "filled" : "outlined"} checked={stackVisibility.stack3} />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              {/* <ZoomInIcon variant="contained" onClick={handleZoomOut} style={{ cursor: 'pointer', marginRight: '10px', width: 40, height: 40 }} />

              <ZoomOutIcon variant="contained" onClick={handleZoomIn} style={{ cursor: 'pointer', marginRight: '10px', width: 40, height: 40 }} /> */}
              <Chip
                  onClick={scrollToTable}
                  label={'View Table'}
                  style={{
                    height:40,
                    marginLeft: 10,
                    width:130,
                    marginTop: 5,
                    fontSize:17
                  }}
                >
                  VIEW TABLE
                </Chip>
            </div>

          </ThemeProvider>
        </div>
      </div>

        {!(race && day) ? (
          <div className="grid-demo-no-data-container">
            {message && (
              <>
                <p className="grid-demo-no-data-text">{message}</p>
                <i className="grid-demo-info-icon fas fa-info-circle"></i>
              </>
            )}
          </div>)

          : racingData1.length === 0 ? (
            <div className="no-data-container" style={{ textAlign: 'center', width:'100%' }}>
              <Typography variant="h6" gutterBottom>
                No data inserted yet!
              </Typography>
              <Chip
                label="Insert Data"
                color="primary"
                onClick={() => {
                  // Redirect to the desired route
                  window.location.href = '/racing-space/racing-data';
                }}
                style={{ cursor: 'pointer', fontSize: '16px', padding: '10px' }}
              />
            </div>
          ) :(<div className='graphContainer' style={{ display: 'flex', justifyContent: 'center' }}>


            <BarChart
              tooltip={{ trigger: 'item' }}
              slotProps={{ legend: { hidden: isHidden } }}
              axisHighlight={{
                y: 'line',
              }}
              width={chartWidth}
              height={650}
              series={[
                stackVisibility.stack1 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.camber.max * 33 / 100) * 1000) / 1000),
                  stack: 'stack1',
                  yAxisKey: 'leftAxisId',
                  label: 'Camber lower',
                  color: '#B2DFDB',

                  valueFormatter: (value) => {

                    const containsItem = racingData1.find(dataItem => dataItem.stats.camber.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.camber === containsItem.stats.camber.min);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name}  ${name.surname})  ${correspondingItem.camber}`;
                  },
                  id: 'pvId',
                },
                stackVisibility.stack1 && racingData1.length > 0 && {

                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.camber.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.camber.max === Math.round((value * 100 / 33) * 1000) / 1000);

                    return ` ${containsItem.stats.camber.avg}`;
                  },
                  stack: 'stack1',
                  yAxisKey: 'leftAxisId',
                  label: 'Camber avg',
                  color: '#00897B',

                },
                stackVisibility.stack1 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.camber.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.camber.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.camber === containsItem.stats.camber.max);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return `  ( ${name.name} ${name.surname}) ${correspondingItem.stats.camber.max}`;
                  },
                  stack: 'stack1',
                  yAxisKey: 'leftAxisId',
                  label: 'Camber higher',
                  color: '#004D40',

                },
                stackVisibility.stack2 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                  stack: 'stack2',
                  yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Lap Time min',
                  color: '#BBDEFB',
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                    const correspondingItem = racingData1.find(dataItem => dataItem.laptimeCalc === containsItem.stats.laptimeCalc.min);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})  ${correspondingItem.laptime}`;
                  },
                },
                stackVisibility.stack2 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                    return `        ${containsItem.stats.laptimeCalc.avg}`;
                  },
                  stack: 'stack2',
                  yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Lap Time avg',
                  color: '#1E88E5',
                },
                stackVisibility.stack2 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value, index) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                    const correspondingItem = racingData1.find(dataItem => dataItem.laptimeCalc === containsItem.stats.laptimeCalc.max);


                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname}) ${correspondingItem.laptime}`;
                  },
                  stack: 'stack2',
                  yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Lap Time max',
                  color: '#0D47A1',

                },
                stackVisibility.stack3 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.caster.max * 33 / 100) * 1000) / 1000),
                  stack: 'stack3',
                  yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Caster min',
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.caster.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.caster === containsItem.stats.caster.min);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})   ${correspondingItem.caster}`;
                  },
                  color: '#E1BEE7',
                },

                stackVisibility.stack3 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.caster.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.caster.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    return ` ${containsItem.stats.caster.avg}`;
                  },
                  stack: 'stack3',
                  yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Caster avg',
                  color: '#8E24AA',
                },
                stackVisibility.stack3 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.caster.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.caster.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.caster === containsItem.stats.caster.max);

                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname}) ${correspondingItem.stats.caster.max}`;
                  },
                  stack: 'stack3',
                  yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Caster max',
                  color: '#4A148C',
                },

              ].filter(Boolean)}
              xAxis={[{ data: racingData1.map((item) => item.session_number), scaleType: 'band' }]}
              yAxis={[
                { id: 'leftAxisId', domain: [100, 1000000000000000000] }, // Adjust the domain values as needed
                { id: 'rightAxisId', domain: [100, 1000000000000000000] }, // Adjust the domain values as needed
              ]}
              rightAxis="rightAxisId"





            >

            </BarChart>
          </div>)} </>
      {racingData1?.length > 0 && (<div>
        <div className='custom-table' ref={tableRef}>
          <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE TABLE  </Typography>

        </div>

        <div className='table-buttons'>
          <ThemeProvider theme={theme}>
            <Box gap={4}>
              <Chip onClick={() => handleButtonClick('axleposition')} label="AXLE POSITION" sx={{ margin: 1 }} color='default' size="medium" variant={axlepositionVisible ? "contained" : "outlined"} />
              <Chip onClick={() => handleButtonClick('axletype')} label="AXLE TYPE" sx={{ margin: 1 }} color="default" size="medium" variant={axletypeVisible ? "contained" : "outlined"} />
              {!isNaN(selectedData.temperature) && (<Chip onClick={() => handleButtonClick('temperature')} label="TEMPERATURE" sx={{ margin: 1 }} color="default" size="medium" variant={temperatureVisible ? "contained" : "outlined"} />)}
              <Chip onClick={() => handleButtonClick('chassisNumber')} label="CHASSIS NUMBER" sx={{ margin: 1 }} color="default" size="medium" variant={chassisNumberVisible ? "contained" : "outlined"} />
            </Box>
          </ThemeProvider>
        </div>


        <TableContainer>
          <Table>
          <TableHead>
                            <TableRow sx={{ background: 'rgba(0, 0, 0, 0.00)', border: '1px solid lightgrey', padding: '20px' }}>
                                <TableCell>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'bold' }}>
                                        <span>Session Number</span>
                                        <hr style={{ width: '50%' }} />
                                        <span>Temperature</span>
                                    </div>
                                </TableCell>
                                {selectedData.map((item, index) => {
                                    if (sessionNumbers.includes(item.session_number)) {
                                        return null;
                                    } else {
                                        sessionNumbers.push(item.session_number);
                                        numberOfCells++;
                                        return (
                                            <TableCell key={`session-number-${index}`} align="center" sx={{maxWidth:1}}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap:35 }}>
                                                    <span style={{ fontWeight: 'bold' }}>{item.session_number}</span>
                                                    {!isNaN(item.temperature) && (
                                                        <Typography variant="body2" style={{ color: 'black', fontWeight: 'normal' }}>
                                                            {item.temperature}°
                                                        </Typography>
                                                    )}
                                                </div>
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        </TableHead>
            <TableBody>
              <TableRow>
                {/*<TableCell sx={{ borderLeft: '1px solid lightgrey' }} />
              {Array.from({ length: numberOfCells }, (_, index) => (
                <TableCell key={index} sx={{ borderRight: '1px solid lightgrey' }}>
                  Runs

                </TableCell>
              ))}
              ))}*/}
              </TableRow>

              {axlepositionVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>AXLE POSITION</TableCell>}
              {axlepositionVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}


              {axlepositionVisible && selectedName && selectedName.map((selectedName, index) => (

                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.axle_position && item.axle_position.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-axle_position`}>{item.axle_position}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-axle_position`}>{item.axle_position}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}

              {axletypeVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>AXLE TYPE</TableCell>}
              {axletypeVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
              {axletypeVisible && selectedName && selectedName.map((selectedName, index) => (

                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.axle_type && item.axle_type.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-axle_type`}>{item.axle_type}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-axle_type`}>{item.axle_type}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}

              {chassisNumberVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>CHASSIS NUMBER</TableCell>}
              {chassisNumberVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
              {chassisNumberVisible && selectedName && selectedName.map((selectedName, index) => (
                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.chassis_number && item.chassis_number.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-chassis_number`}>{item.chassis_number}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-chassis_number`}>{item.chassis_number}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}




            </TableBody>
          </Table>
        </TableContainer>
      </div>)}
    </div>
  );
};

export default observer(MyComponent);