import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";

const DistributionDialog = ({ open, onClose }) => {
  const [selectedChip, setSelectedChip] = useState(null);

  // Fetch data distribution when dialog opens
  useEffect(() => {
    if (open) {
      axios
        .get(`${process.env.REACT_APP_API_DOMAIN}/get_data_distribution/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        })
        .then((response) => {
          // Set selectedChip based on the response
          const distribution = response.data?.data_distribution;
          setSelectedChip(distribution); // Expecting 0 or 1
        })
        .catch((error) => {
          console.error("Error fetching data distribution:", error);
        });
    }
  }, [open]); // Trigger when `open` changes

  const handleChipClick = async (value) => {
    setSelectedChip(value);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/change_data_distribution/?new_data_distribution=${value}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Data distribution changed:", response.data);
      })
      .catch((error) => {
        console.error("Error changing data distribution:", error);
      });
  };

  const getDescription = () => {
    if (selectedChip === 0) {
      return " With Distribution 1, AiMotor retrieves only the fastest lap , but from diffferent sessions. It starts from the last session added and continuous until the maximum number of laps is reached";
    }
    if (selectedChip === 1) {
      return " With Distribution 2 , AiMotor retrieves more than one lap, but  only from the latest session. ";
    }
    return "Select a distribution to view its description.";
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "8px",
          padding: "20px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
          Select a Distribution
        </Typography>
      </DialogTitle>
      <Typography variant="h7" sx={{textAlign: "center" }}>
      AiMotor 2 can retrive  up to 10 laps from the current day from you ad your teammates. AiMotor 1 always 10 laps but just from you
        </Typography>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            mb: 2,
          }}
        >
          <Chip
            label="Distribution 1"
            color={selectedChip === 0 ? "primary" : "default"}
            onClick={() => handleChipClick(0)}
            sx={{
              fontSize: "1rem",
            }}
          />
          <Chip
            label="Distribution 2"
            color={selectedChip === 1 ? "primary" : "default"}
            onClick={() => handleChipClick(1)}
            sx={{
              fontSize: "1rem",
            }}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            margin: "10px 0",
            textAlign: "center"
          }}
        >
          {getDescription()}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Chip
          label="Close"
          variant="outlined"
          color="primary"
          onClick={onClose}
          sx={{
            fontSize: "1rem",
            borderWidth: "2px",
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DistributionDialog;
