import React, { useState } from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questions = [
    {
      question: "What is AiMotor?",
      answer: "AiMotor is an AI-powered platform designed to help drivers improve their driving style through telemetry data and to optimize their setup.",
    },
    {
      question: "How do I subscribe to AiMotor?",
      answer: "You can subscribe to AiMotor by selecting a plan from the pricing page and completing the registration process.",
    },
    {
      question: "Is there a free trial available?",
      answer: "Yes, AiMotor offers one month free trial for all plans, allowing you to explore all the features before committing to a subscription.",
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: "Yes, you can cancel your subscription anytime through your account settings. There are no hidden fees or penalties.",
    },
    {
      question: "Does AiMotor support team collaborations?",
      answer: "Yes, AiMotor allows team members to share data and collaborate for improved performance and personalized recommendations.",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        width: "100%",
        padding: "40px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems:  "center" ,
        justifyContent: "space-between",
        minHeight: "auto", // Height adjusts to content
      }}
    >
      {/* Left Side: Title */}
      <Box
        sx={{
          flex: "1",
          textAlign: { xs: "center", md: "left" },
          marginBottom: { xs: "20px", md: "0" },
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontWeight: "bold",
            background: "linear-gradient(90deg, #7e57c2, #5e35b1)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Frequently Asked Questions
        </Typography>
      </Box>

      {/* Right Side: FAQ Content */}
      <Box sx={{ flex: "2", maxWidth: "800px", width: "100%" }}>
        {questions.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              backgroundColor: "#000", // Consistent black background
              color: "#fff",
              borderRadius: "8px",
              marginBottom: "15px", // Space between boxes
              "&:hover": {
                backgroundColor: "#333", // Light gray hover effect
              },
              boxShadow: "none",
              transition: "background-color 0.3s ease",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                padding: "16px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "16px",
                borderTop: "1px solid #444",
              }}
            >
              <Typography sx={{ fontSize: "1rem", color: "#ccc" }}>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
