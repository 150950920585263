import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DriversDates from 'components/Steppers/DriversDates/DriversDates.js';

import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Hidden } from '@mui/material';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import CorniceEngine from './Try_CorniceEngine';
import CorniceFront from './Try_CorniceFront';
import CornicePressure from './Try_CornicePressure';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Mmenu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OutlinedInput from '@mui/material/OutlinedInput'; // Corrected import path for OutlinedInput component
import { PieChart } from '@mui/x-charts/PieChart';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import myUserStore from '../../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { useTheme } from '@mui/material/styles';
import drivers1 from '../TryFiles/get_complete_drivers.json';

const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};









function getStyles(name, personName, theme) { // Pass 'name' and 'personName' as arguments
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const competitions = [
  {
    "id": 4,
    "competition": "AiMotor Race",
    "track": "Lonato"
  },
]


const PieChartWithSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [racingData, setRacingData] = useState([]);
  const [selectedDriverDates, setSelectedDriverDates] = useState('');

  const [showFront, setShowFront] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState(drivers1);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true); // Use showEngine for engine graphs
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [step, setStep] = useState(0);
  const [openDriverDates, setOpenDriverDates] = useState(true);
  const comp = competitions?.find((competition) => competition.id === race)
  const [selectedSessions, setSelectedSessions] = useState([1]);

  const [stepPayload, setStepPayload] = useState({
    'race': race,
    'day': day,
    'drivers': [],
    'laps': [],
    'driverSessions': [],

  })
  const handleArrayChange = (array) => {
    console.log("array changed", array)
    setStepPayload(array);
    setDriver(array.drivers.map(driver => driver.value))
    setDay(array.day)
    setRace(array.race)
    //setEmailName(array.drivers)

  }
  const handleFilterClick = (number, name) => {
    setOpenDriverDates(true);
    setStep(number);
    setCompleted(false);
    if (name) {
      setSelectedDriverDates(name)
    }
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0)
    setShowFront(newValue === 1)
    setShowPressure(newValue === 2)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleMenuClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;

  useEffect(() => {
    const filterDataByDriverSessions = (data) => {
      if (driver?.length > 0) {
        return data.filter(item =>
          driver.includes(item.email)
        );
      } else {
        return data
      }

    };
    setEmailName(filterDataByDriverSessions(drivers1));
    console.log('full name', filterDataByDriverSessions(drivers1));
  }, [driver, race, day]);









  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log("selected team", selectedTeam.id)


  const handleDoneClick = (event) => {
    // Chiudi il menu della Select
    event.stopPropagation();
    setSelectOpen(false);

  };




  const handleChange = (event) => {
    // If multiple options can be selected, event.target.value will be an array
    const selectedDrivers = event.target.value;
    const { value } = event.target;
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
    }
    setDriver(selectedDrivers);
  };




  {/*const [race, setRace] = useState('');*/ }
  // const [day, setDay] = useState('');


  ;


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDay = (day) => {
    console.log('Selected day:', day);
    setDay(day);
    handleMenuClose();
  };

  const racingDays = [
    { name: 'First Racing Day', value: 1 },
    { name: 'Second Racing Day', value: 2 },
    { name: 'Third Racing Day', value: 3 },
    { name: 'Fourth Racing Day', value: 4 },
    { name: 'Fifth Racing Day', value: 5 },
    { name: 'Sixth Racing Day', value: 6 },
    { name: 'Seventh Racing Day', value: 7 },
  ];
  const handleSessionClick = (session) => {
    setSelectedSessions((prev) => {
      if (prev.includes(session)) {
        return prev.filter((item) => item !== session);
      } else {
        return [...prev, session];
      }
    });
  };



  console.log("email name", emailName);

  return (

    <div>


      <DriversDates isTry={true} notTelem={true} completed={completed} setCompleted={setCompleted} stepPayload={stepPayload} setStepPayload={setStepPayload} isOpen={openDriverDates} setIsOpen={setOpenDriverDates} setStep={setStep} arrayChange={handleArrayChange} step={step} />


      <Grid  >
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <Typography sx={{ fontSize: 40 }} > Setup analytics</Typography>
        </Grid>
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', marginBottom: 30 }}>
          <Chip label={comp ? `${comp.competition} (${comp.track})` : "choose race"} icon={<SwapHorizIcon />} onClick={() => handleFilterClick(0)} />
          <Chip label={day ? ` Day ${day}  ` : 'choose day'} onClick={() => handleFilterClick(1)} />
          <Chip label={drivers?.length > 0 ? 'change drivers' : 'choose drivers'} onClick={() => handleFilterClick(2)} />

          <div>
            {[1, 2, 3, 4, 5].map((session) => (
              <Chip
                key={session}
                label={session}
                color={selectedSessions.includes(session) ? 'primary' : 'default'}
                onClick={() => handleSessionClick(session)}
                sx={{ marginRight: 1 }}
              />
            ))}

          </div>
        </div>
        {/* <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
    <div className="frame1" style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '10px', width: '100%' }}>

          <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)', minWidth: 100 }}>
<InputLabel id="race-select-label">RACE</InputLabel>
<Select
  labelId="race-select-label"
  fullWidth
  id="race-select"
  value={race}
  onChange={(e) => {setRace(e.target.value); 
     setDriver([]); }}
  label="Race"
>
  {!competitionLoading ?
    (competitions.length === 0 ? 
      <MenuItem value="">Please Insert a Competition</MenuItem>
     :
      [
        <MenuItem key="select-race" value="">Select Race</MenuItem>,
        ...competitions.map((competition) => (
          <MenuItem key={competition.id} value={competition.id}>
            {competition.competition} ({competition.track})
          </MenuItem>
        ))
      ]
    ) : (
      <MenuItem value="" disabled>
        <Skeleton variant="rectangular" sx={{ borderRadius: '8px', marginLeft: 0.5 }} height={30} />
      </MenuItem>
    )}
</Select>


</FormControl>
<Button
  className="button"
  aria-controls="number-menu"
  aria-haspopup="true"
  onClick={handleMenuOpen}
  style={{ whiteSpace: 'nowrap', color: (!day ? 'red' : 'green') }}
>
  <CalendarMonthIcon className="calendar-month" />
</Button>
<Mmenu
  id="number-menu"
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
>
  {racingDays.map((racingDay) => (
    <MenuItem key={racingDay.value} onClick={() => handleSelectDay(racingDay.value)}>
      {racingDay.name}
    </MenuItem>
  ))}
</Mmenu>
    </div>

  </Grid>
  {!showPersonalButton && (<Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>

    <div className="autocomplete-wrapper" style={{ width: "100%" }}>
      <div className="autocomplete" style={{ width: '100%' }}>
      <FormControl sx={{}} style={{ width: '100%' }}>
              <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={driver}
                label="driver "
                onChange={handleChange}
                open={selectOpen}
                onClose={() => setSelectOpen(false)}
                onOpen={() => setSelectOpen(true)}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const selectedName = emailName.find(dataItem => dataItem.email === value);
                      const name = selectedName ? selectedName.surname : '';
                      return (
                        <Chip key={value} label={name} />
                      );
                    })}
                  </Box>
                )}
              >
                {drivers.map((driver) => (
                  <MenuItem key={driver.id} value={driver.email}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      {driver.surname}
                      {lastSelectedDriver === driver.email && (
                        <Chip
                            label="done"
                            color="success"
                            size="small"
                            onClick={handleDoneClick}
                          />
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
      </div>
    </div>
  </Grid>)}*/}

      </Grid>



      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} >
            <Tab label="ENGINE" {...a11yProps(0)} />
            <Tab label="CHASSIS" {...a11yProps(1)} />
            <Tab label="PRESSURE" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <div className='graphs'>
          <>
            {showEngine && <CorniceEngine race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} selectedSessions={selectedSessions} />}
            {showFront && <CorniceFront race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} selectedSessions={selectedSessions} />}
            {showPressure && <CornicePressure race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} selectedSessions={selectedSessions} />}
          </>

        </div>

      </Box>








    </div>
  );
};

export default observer(PieChartWithSelect);
