import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from '@mui/material';

// Import the logos as you did in HeroSection
import sponsorImage from './sponsor_3.png';
import sponsorImage2 from './sponsor_2.png';

const testimonials = [
  {
    logo: sponsorImage, // Use the imported sponsorImage
    text: 'I was able to quickly understand telemetry, accelerating my driving development process.',
    keyPhrases: ['quickly validate AI ideas'],
    representative: {
      name: 'Leonardo Baccaglini',
      title: 'DD2 driver',
      company: 'Drake Motorsport',
      photo: 'images/baccaglini.jpg', // Update this path if needed
    },
    link: 'https://www.instagram.com/reel/DCCabyZMZH6/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
  {
    logo: 'images/tony_kart.svg', // Use the imported sponsorImage2
    text: 'My performances increased by 50% thanks to their solutions.',
    keyPhrases: ['productivity increased by 50%'],
    representative: {
      name: 'Sebastiano Pavan',
      title: 'OK driver',
      company: 'Tony Kart Official',
      photo: 'images/pavan.jpg', // Update this path if needed
    },
    link: 'https://www.instagram.com/reel/DBrWSc2tvu6/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
];

const Testimonials = () => {
  const handleCardClick = (link) => {
    window.open(link, '_blank'); // Open the link in a new tab
  };

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        py: 6,
        textAlign: 'center',
        paddingBottom: '150px',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: '2.5rem',
          marginBottom: '2rem',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Our Customers
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid item xs={11} md={5} key={index}>
            <Card
              onClick={() => handleCardClick(testimonial.link)}
              sx={{
                borderRadius: '20px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                padding: 3,
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                cursor: 'pointer', // Show pointer on hover
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.03)', // Add a hover effect
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center', // Center vertically
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    marginBottom: 2,
                  }}
                >
                  <img
                    src={testimonial.logo}
                    alt="Customer Logo"
                    style={{ height: 40 }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    fontStyle: 'italic',
                    color: '#555',
                    marginBottom: 3,
                    fontSize: '1rem',
                    lineHeight: '1.6',
                  }}
                >
                  “{testimonial.text}”
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 'auto',
                }}
              >
                <Avatar
                  src={testimonial.representative.photo}
                  alt={testimonial.representative.name}
                  sx={{
                    width: 50,
                    height: 50,
                    marginRight: 2,
                  }}
                />
                <Box textAlign="left">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      color: '#333',
                    }}
                  >
                    {testimonial.representative.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#666',
                    }}
                  >
                    {testimonial.representative.title} @{' '}
                    {testimonial.representative.company}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;
