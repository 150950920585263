import { useRef, useState, useEffect } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

const YourComponent = () => {
  const scrollRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');
  const images = isMobile
    ? [
        "images/1_picture_mob.jpg",
        "images/2_picture_mob.jpg",
        "images/3_picture_mob.jpg",
        "images/4_picture_mob.jpg",
        "images/5_picture_mob.jpg",
      ]
    : isTablet
    ? [
        "images/ipad1.jpg",
        "images/ipad2.jpg",
        "images/ipad3.jpg",
        "images/ipad4.jpg",
        "images/ipad5.jpg",
      ]
    : [
        "images/5_picture_pc.jpg",
        "images/1_picture_pc.jpg",
        "images/4_picture_pc.jpg",
        "images/3_picture_pc.jpg",
        "images/2_picture_pc.jpg",
      ];

  const imagesRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (imagesRefs.current[currentIndex]) {
        imagesRefs.current[currentIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [currentIndex]);

  const handleScroll = (direction) => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex;

      if (direction === 'left') {
        newIndex = Math.max(prevIndex - 1, 0);
      } else {
        newIndex = Math.min(prevIndex + 1, images.length - 1);
      }

      return newIndex;
    });
  };

  return (
    <Box className="second-part-home">
      <Container maxWidth={false} className="container-lg" sx={{ position: 'relative' }}>
        <Grid
          container
          rowSpacing={{ xs: '160px', lg: '200px' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={10} sm={12} md={12} lg={11}>
            <Box textAlign={{ xs: 'center' }} mr="auto">
              <div></div>
            </Box>
          </Grid>

          {/* Horizontal scroll container */}
          <Box
            ref={scrollRef}
            sx={{
              display: 'flex',
              overflowX: 'auto',
              position: 'relative',
              scrollBehavior: 'smooth',
              width: '80%',
              margin: '0 auto',
              '& img': {
                flexShrink: 0,
                width: 'auto',
                maxWidth: '100%',
                marginRight: '35px',
                borderRadius: '12px',
              },
            }}
          >
            {images.map((src, index) => (
              <img
                key={index}
                ref={(el) => (imagesRefs.current[index] = el)}
                src={src}
                alt={`image${index + 1}`}
              />
            ))}
          </Box>

          {/* Left Scroll Button */}
          <Button
            onClick={() => handleScroll('left')}
            disabled={currentIndex === 0}
            style={{
              position: 'absolute',
              left: '0px',
              top: '50%',
              zIndex: 10,
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              transform: 'translateY(-50%)',
            }}
            sx={{
              '&.Mui-disabled': {
                backgroundColor: 'rgba(0, 0, 0, 0.26)',
              },
            }}
          >
            <ArrowBackIos
              style={{
                color: currentIndex === 0 ? 'gray' : 'white',
                fontSize: '20px',
              }}
            />
          </Button>

          {/* Right Scroll Button */}
          <Button
            onClick={() => handleScroll('right')}
            disabled={currentIndex === images.length - 1}
            style={{
              position: 'absolute',
              right: '0px',
              top: '50%',
              zIndex: 10,
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              transform: 'translateY(-50%)',
            }}
            sx={{
              '&.Mui-disabled': {
                backgroundColor: 'rgba(0, 0, 0, 0.26)',
              },
            }}
          >
            <ArrowForwardIos
              style={{
                color: currentIndex === images.length - 1 ? 'gray' : 'white',
                fontSize: '20px',
              }}
            />
          </Button>
        </Grid>
      </Container>
    </Box>
  );
};

export default YourComponent;
